import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/uireact/uireact/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { ExtendStyleExpample } from './helpers/extend-styling-example.tsx';
import { JustACard } from './helpers/just-a-card.tsx';
import { UiCard } from '@uireact/card';
import { UiLineSeparator } from '@uireact/separator';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "extending-a-component-styling-"
    }}>{`Extending a component styling ✨`}</h1>
    <UiLineSeparator mdxType="UiLineSeparator" />
    <br />
    <p>{`We know that there are endless number of use cases and a library to fit them all is not efficient nor possible, so with styled components
you can extend the styling of a component, this is how you would do it.`}</p>
    <h2 {...{
      "id": "using-styled-components-"
    }}>{`Using styled components 💅🏿`}</h2>
    <p>{`This library is built with styled component, so we suggest you only use it in your main project.`}</p>
    <p>{`For demo purposes let's render a normal `}<inlineCode parentName="p">{`UiCard`}</inlineCode>{` first, so we can see the differences:`}</p>
    <JustACard mdxType="JustACard" />
    <p>{`Now, in our client app we would import styled components and apply a custom style to the `}<inlineCode parentName="p">{`UiCard`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`  import styled from 'styled-components';

  import { UiCard } from '@uireact/card';

  const CustomUiCard = styled(UiCard)\`
    border-radius: 20px 50px 20px 50px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  \`;

  export const MyComponent = () => {
    return <CustomUiCard>Some content<CustomUiCard>;
  }
`}</code></pre>
    <ExtendStyleExpample mdxType="ExtendStyleExpample" />
    <br />
    <br />
    <p>{`You can see the component used in this example `}<a parentName="p" {...{
        "href": "https://github.com/inavac182/uireact/tree/main/docs/helpers/extend-styling-example.tsx"
      }}>{`Here`}</a></p>
    <h2 {...{
      "id": "using-classes"
    }}>{`Using classes`}</h2>
    <p>{`If you are using something like tailwind CSS then you should be able to just pass the class you need applied through the className prop.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      